.main-holder {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
}

.transparent-background {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9998;
}

.popup-window {
  width: 90%;
  max-width: 550px;
  max-height: 90%;
  padding: 30px;
  padding-bottom: 40px;
  border-radius: 4px;
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 20, 0.15);
  overflow-y: scroll;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  scrollbar-width: none;
  -ms-overflow-style: none;
  z-index: 9999;
  box-sizing: border-box;
}
.company-logo {
  height: 30px;
  margin-left: 20px;
}

.parent-width {
  width: 100%;
}
.parent-height {
  height: 100%;
}
.parent-size {
  width: 100%;
  height: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}
.row-reverse {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}
.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
}

/* ///// */

.headerCouponButton {
  overflow: hidden;
}

.headerMenuButton {
  width: 0px;
  overflow: hidden;
}

.menu {
  width: 25%;
  min-width: 23 0px;
  height: 100%;
}

.menuFull {
  width: 100%;
  height: 100%;
}

.mainBody {
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  background-color: white;
  overflow-x: scroll;
  padding: 30px;
}

.mainBodyHidden {
  width: 0%;
  height: 0%;
  padding: 0px;
  overflow: hidden;
}

/* ///// */

.border-box {
  box-sizing: border-box;
}
.display-block {
  display: inline-block;
}

.cursor-hand {
  cursor: pointer;
}

.justify-content-around {
  justify-content: space-around;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}

.icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

/* BORDER */
.border-s {
  border: solid;
  border-width: 1px;
  border-color: rgb(240, 240, 240);
}
.border-m {
  border: solid;
  border-width: 1px;
  border-color: rgb(220, 220, 220);
}

.border-red {
  border: solid;
  border-width: 1px;
  border-color: red;
}

/* BORDER RADIUS */

.border-radius-s {
  border-radius: 4px;
}
.border-radius-m {
  border-radius: 8px;
}
.border-radius-r {
  border-radius: 12px;
}
.border-radius-xxl {
  border-radius: 35px;
}

/* SHADOW */
.shadow-s {
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);
}
.shadow-m {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
}

.text-gray {
  color: gray;
}

.text-white {
  color: white;
}

.bg-gray {
  background-color: gray;
}
.bg-gray1 {
  background-color: #f4f4f6;
}
.bg-gray2 {
  background-color: #e9ebed;
}
.bg-gray3 {
  background-color: #dfe1e2;
}
.bg-gray4 {
  background-color: #cccfd1;
}
.bg-gray5 {
  background-color: #b7bfc6;
}
.bg-line {
  background-color: rgb(242, 242, 242);
}

.pl-r {
  padding-left: 15px;
}
.pr-r {
  padding-right: 15px;
}
.pt-r {
  padding-top: 15px;
}
.pb-r {
  padding-bottom: 15px;
}

.ml-r {
  margin-left: 15px;
}

.ml-s {
  margin-left: 6px;
}

/* FONT SIZE */

.fs-r {
  font-size: 13px;
}
.fs-xs {
  font-size: 8px;
}
.fs-s {
  font-size: 10px;
}
.fs-m {
  font-size: 12px;
}
.fs-l {
  font-size: 16px;
}
.fs-xl {
  font-size: 20px;
}
.fs-xxl {
  font-size: 24px;
}
.fs-xxxl {
  font-size: 30px;
}

.circle {
  border-radius: 50%;
}

.ic-contact-r {
  width: 30px;
  height: 30px;
}

/* FONT WEIGHT */

.fw-l {
  font-weight: lighter;
}
.fw-r {
  font-weight: normal;
}
.fw-m {
  font-weight: 600;
}
.fw-b {
  font-weight: bold;
}
.fw-h {
  font-weight: bolder;
}

/* TEXT ALIGNMENT */

.text-wrap-none {
  white-space: nowrap;
}
.text-wrap {
  word-wrap: break-word;
}
.text-trailing {
  text-overflow: ellipsis;
}

.text-align-left {
  text-align: left;
}
.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}
.text-align-justify {
  text-align: right;
}

.TextField2 {
  background-color: transparent;
  font-size: 13px;
  outline-width: 0;
  outline-style: solid;
  width: 100%;
  border: solid;
  border-color: transparent;
  border-width: 1px;
}

.listPickerBG {
  background-color: transparent;
}
.listPickerBG:hover {
  background-color: #f4f4f6;
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.priceDetailsPopup {
  background-color: rgb(245, 245, 245);
  width: 300px;
}
.priceDetailsBottomPopup {
  height: 0px;
  overflow: hidden;
}

.productThumbnail {
  width: 16.6%;
}

@media only screen and (max-width: 1000px) {
  .productThumbnail {
    width: 22%;
  }
}

@media only screen and (max-width: 800px) {
  .priceDetailsBottomPopup {
    height: auto;
    overflow: visible;
  }
  .priceDetailsPopup {
    width: 0px;
  }
  .productThumbnail {
    width: 30%;
  }
}

@media only screen and (max-width: 600px) {
  .menu {
    width: 0%;
    min-width: 0px;
  }
  .headerMenuButton {
    width: 35px;
    margin-right: 5px;
  }
  .headerCouponButton {
    width: 0px;
    overflow: hidden;
  }
  .productThumbnail {
    width: 47%;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .Loading-logo {
    animation: Loading-logo-spin infinite 3s linear;
  }
}

@keyframes Loading-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Rotate {
  position: absolute;
  transform: rotate(0.75turn);
}
