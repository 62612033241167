.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  background-color: rgb(245, 245, 245);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.RowDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.RowReverseDiv {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
}
.ColumnDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.HandCursor {
  cursor: pointer;
}
.MouseCursor {
  cursor: default;
}

.TextField {
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  padding-top: 13px;
  padding-bottom: 13px;
  margin-bottom: 10px;
  font-size: 13px;
  /* box-shadow: 0 0 2px rgba(0, 0, 0, 0.3); */
  outline-width: 0;
  outline-style: solid;
  width: 91%;
  border: solid;
  border-color: rgb(230, 230, 230);
  border-width: 1px;
}
.ListPicker {
  width: 99%;
  padding: 10px;
  border-radius: 8px;
  color: black;
  border-color: rgb(230, 230, 230);
  outline: none;
  appearance: none;
  /* background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px; */
}

.ColorPicker {
  border: solid;
  border-width: 1px;
  border-radius: 6px;
  border-color: rgb(200, 200, 200);
  padding: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.Menu {
  width: 100%;
  height: 7vh;
  background-color: white;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
}
.MenuShadow {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

.ListPickerHolderStyle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
}
.ListPickerArrowStyle {
  position: absolute;
  bottom: 14px;
  right: 13px;
  width: 8px;
  opacity: 0.8;
}

.DownArrow {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid white;
}

.WindowPopup {
  width: 80%;
  height: 80%;
  padding: 1.3vw;
  border-radius: 12px;
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  overflow: scroll;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  scrollbar-width: none;
  -ms-overflow-style: none;
  z-index: 9999;
}
.WindowPopup::-webkit-scrollbar {
  display: none;
}
.TransparentBackground {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9998;
}

.Shadow {
  box-shadow: 10px 10px 10px 2px rgba(0, 0, 0, 0.15);
}
.ShadowSmall {
  box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.05);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* FONT CSS */

.Font1 {
  font-size: 0.7vw;
}
.Font2 {
  font-size: 0.9vw;
}
.Font3 {
  font-size: 1vw;
}
.Font4 {
  font-size: 1.3vw;
}
.Font5 {
  font-size: 1.4vw;
}
.Font6 {
  font-size: 1.8vw;
}
.Font7 {
  font-size: 2vw;
}
.Font8 {
  font-size: 2.6vw;
}
.Font9 {
  font-size: 2.8vw;
}
.Font10 {
  font-size: 3.8vw;
}
.Font11 {
  font-size: 6vw;
}

/* PADDING HORIZONTAL CSS */
/* PADDING HORIZONTAL CSS */
/* PADDING HORIZONTAL CSS */
/* PADDING HORIZONTAL CSS */

.PaddingHorizontal0 {
  padding-left: 0vw;
  padding-right: 0vw;
}
.PaddingHorizontal2 {
  padding-left: 2vw;
  padding-right: 2vw;
}
.PaddingHorizontal4 {
  padding-left: 4vw;
  padding-right: 4vw;
}
.PaddingHorizontal6 {
  padding-left: 6vw;
  padding-right: 6vw;
}
.PaddingHorizontal8 {
  padding-left: 8vw;
  padding-right: 8vw;
}
.PaddingHorizontal10 {
  padding-left: 10vw;
  padding-right: 10vw;
}
.PaddingHorizontal12 {
  padding-left: 12vw;
  padding-right: 12vw;
}
.PaddingHorizontal14 {
  padding-left: 14vw;
  padding-right: 14vw;
}
.PaddingHorizontal16 {
  padding-left: 16vw;
  padding-right: 16vw;
}
.PaddingHorizontal18 {
  padding-left: 18vw;
  padding-right: 18vw;
}
.PaddingHorizontal20 {
  padding-left: 20vw;
  padding-right: 20vw;
}
.PaddingHorizontal22 {
  padding-left: 22vw;
  padding-right: 22vw;
}
.PaddingHorizontal24 {
  padding-left: 24vw;
  padding-right: 24vw;
}
.PaddingHorizontal26 {
  padding-left: 26vw;
  padding-right: 26vw;
}
.PaddingHorizontal28 {
  padding-left: 28vw;
  padding-right: 28vw;
}
.PaddingHorizontal30 {
  padding-left: 30vw;
  padding-right: 30vw;
}

/* PADDING VERTICAL CSS */
/* PADDING VERTICAL CSS */
/* PADDING VERTICAL CSS */
/* PADDING VERTICAL CSS */

.PaddingVertical0 {
  padding-top: 0vw;
  padding-bottom: 0vw;
}
.PaddingVertical2 {
  padding-top: 2vw;
  padding-bottom: 2vw;
}
.PaddingVertical4 {
  padding-top: 4vw;
  padding-bottom: 4vw;
}
.PaddingVertical6 {
  padding-top: 6vw;
  padding-bottom: 6vw;
}
.PaddingVertical8 {
  padding-top: 8vw;
  padding-bottom: 8vw;
}
.PaddingVertical10 {
  padding-top: 10vw;
  padding-bottom: 10vw;
}
.PaddingVertical12 {
  padding-top: 12vw;
  padding-bottom: 12vw;
}
.PaddingVertical14 {
  padding-top: 14vw;
  padding-bottom: 14vw;
}
.PaddingVertical16 {
  padding-top: 16vw;
  padding-bottom: 16vw;
}
.PaddingVertical18 {
  padding-top: 18vw;
  padding-bottom: 18vw;
}
.PaddingVertical20 {
  padding-top: 20vw;
  padding-bottom: 20vw;
}
.PaddingVertical22 {
  padding-top: 22vw;
  padding-bottom: 22vw;
}
.PaddingVertical24 {
  padding-top: 24vw;
  padding-bottom: 24vw;
}
.PaddingVertical26 {
  padding-top: 26vw;
  padding-bottom: 26vw;
}
.PaddingVertical28 {
  padding-top: 28vw;
  padding-bottom: 28vw;
}
.PaddingVertical30 {
  padding-top: 30vw;
  padding-bottom: 30vw;
}

/* RESPONSIVE */
/* RESPONSIVE */
/* RESPONSIVE */
/* RESPONSIVE */
/* RESPONSIVE */

@media screen and (min-width: 1440px) {
  .Font1 {
    font-size: 10.01px;
  }
  .Font2 {
    font-size: 12.87px;
  }
  .Font3 {
    font-size: 14.3px;
  }
  .Font4 {
    font-size: 18.59px;
  }
  .Font5 {
    font-size: 20.02px;
  }
  .Font6 {
    font-size: 25.74px;
  }
  .Font7 {
    font-size: 28.6px;
  }
  .Font8 {
    font-size: 37.18px;
  }
  .Font9 {
    font-size: 40.04px;
  }
  .Font10 {
    font-size: 54.34px;
  }
  .Font11 {
    font-size: 85.66px;
  }

  /* HORIZONTAL PADDING */
  /* HORIZONTAL PADDING */
  /* HORIZONTAL PADDING */
  /* HORIZONTAL PADDING */

  .PaddingHorizontal0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .PaddingHorizontal2 {
    padding-left: 28.6px;
    padding-right: 28.6px;
  }
  .PaddingHorizontal4 {
    padding-left: 57.2px;
    padding-right: 57.2px;
  }
  .PaddingHorizontal6 {
    padding-left: 85.4px;
    padding-right: 85.4px;
  }
  .PaddingHorizontal8 {
    padding-left: 114.4px;
    padding-right: 114.4px;
  }
  .PaddingHorizontal10 {
    padding-left: 143px;
    padding-right: 143px;
  }
  .PaddingHorizontal12 {
    padding-left: 171px;
    padding-right: 171px;
  }
  .PaddingHorizontal14 {
    padding-left: 200px;
    padding-right: 200px;
  }
  .PaddingHorizontal16 {
    padding-left: 230px;
    padding-right: 230px;
  }
  .PaddingHorizontal18 {
    padding-left: 258px;
    padding-right: 258px;
  }
  .PaddingHorizontal20 {
    padding-left: 290px;
    padding-right: 290px;
  }
  .PaddingHorizontal22 {
    padding-left: 315px;
    padding-right: 315px;
  }
  .PaddingHorizontal24 {
    padding-left: 345px;
    padding-right: 345px;
  }
  .PaddingHorizontal26 {
    padding-left: 370px;
    padding-right: 370px;
  }
  .PaddingHorizontal28 {
    padding-left: 400px;
    padding-right: 400px;
  }
  .PaddingHorizontal30 {
    padding-left: 430px;
    padding-right: 430px;
  }

  /* VERTICAL PADDING */
  /* VERTICAL PADDING */
  /* VERTICAL PADDING */
  /* VERTICAL PADDING */

  .PaddingVertical0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .PaddingVertical2 {
    padding-top: 28.6px;
    padding-bottom: 28.6px;
  }
  .PaddingVertical4 {
    padding-top: 57.2px;
    padding-bottom: 57.2px;
  }
  .PaddingVertical6 {
    padding-top: 85.4px;
    padding-bottom: 85.4px;
  }
  .PaddingVertical8 {
    padding-top: 114.4px;
    padding-bottom: 114.4px;
  }
  .PaddingVertical10 {
    padding-top: 143px;
    padding-bottom: 143px;
  }
  .PaddingVertical12 {
    padding-top: 171px;
    padding-bottom: 171px;
  }
  .PaddingVertical14 {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .PaddingVertical16 {
    padding-top: 230px;
    padding-bottom: 230px;
  }
  .PaddingVertical18 {
    padding-top: 258px;
    padding-bottom: 258px;
  }
  .PaddingVertical20 {
    padding-top: 290px;
    padding-bottom: 290px;
  }
  .PaddingVertical22 {
    padding-top: 315px;
    padding-bottom: 315px;
  }
  .PaddingVertical24 {
    padding-top: 345px;
    padding-bottom: 345px;
  }
  .PaddingVertical26 {
    padding-top: 370px;
    padding-bottom: 370px;
  }
  .PaddingVertical28 {
    padding-top: 400px;
    padding-bottom: 400px;
  }
  .PaddingVertical30 {
    padding-top: 430px;
    padding-bottom: 430px;
  }
}
